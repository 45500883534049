import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'
import ToastPlugin from 'vue-toast-notification';
import VueCookies from 'vue-cookies'
import axios from 'axios';
import App from './App.vue'
import VueSocketIO from 'vue-socket.io'
import io from 'socket.io-client';
import LoadingPlugin from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';



// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
 
Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBBwVgas-qzlQsv5drBEFK-F4MFIU7q-qc",
    libraries: "places"
  },
  installComponents: true
});

Vue.use(new VueSocketIO({
    debug: false,
    connection: io(process.env.VUE_APP_URL_SOCKET), //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
  configureFakeBackend();
}
axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const loggeduser = localStorage.getItem('user');
  if(loggeduser){
    const jsonUser = JSON.parse(loggeduser)
    config.headers['Authorization'] = `Bearer ${jsonUser.token}`;
  }

  // config.headers['test'] = 'test123'
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // const originalRequest = error.config;
  // console.log('error', error.response.status)
  // console.log('original', originalRequest._retry)
  if (error.response.status === 401) {
    localStorage.removeItem('user');
    if (router.app._route.name != 'login') {
      router.push({
          path: "/login",
      });
  }
  }
  return Promise.reject(error);
});

import '@/assets/scss/app.scss'
 
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(ToastPlugin);
Vue.use(VueSweetalert2);
Vue.use(require("vue-chartist"));
Vue.use(VueMask);
Vue.use(VueCookies);
Vue.use(LoadingPlugin);
Vue.component("v-select", vSelect);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
